import "core-js/modules/es.array.push.js";
import common from '@mixins/common';
import scrollList from '@mixins/scrollList';
import getPageList from '@mixins/getPageList';
import { mapGetters } from 'vuex';
import * as couponApi from '@api/coupon';
import { getScanGoodsList } from '@api/coupon';
export default {
  name: 'CouponScanList',
  mixins: [common, scrollList, getPageList],
  components: {},
  computed: {
    ...mapGetters('user', ['projectInfo'])
  },
  data() {
    return {
      searchKey: {
        page: 1,
        size: 20,
        condition: ''
      },
      // 搜索参数
      listData: [],
      tabValue: 0
    };
  },
  created() {
    const {
      token,
      projectId
    } = this.$route.query;
    if (token && projectId) {
      this.$store.commit('user/SET_TOKEN', token);
      this.$store.commit('user/SET_USER_INFO', {
        projectId
      });
      this.$store.commit('user/SET_PROJECT_INFO', {
        projectId
      });
      this.initData();
    }
  },
  methods: {
    async initData() {
      await this.resetParams();
      await this.getListData();
    },
    handleTabChange() {
      this.listData = [];
      this.onSearch();
    },
    onClearSearch() {
      this.searchKey.condition = '';
      this.onSearch();
    },
    onSearch() {
      this.searchKey.page = 1;
      this.getListData({
        isPullDown: true
      });
    },
    async getListData({
      isPullDown,
      isShowLoading
    } = {
      isPullDown: false,
      isShowLoading: true
    }) {
      // 获取列表数据
      try {
        this.isLoading = true;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', true);
        let {
          page,
          size,
          condition
        } = this.searchKey;
        let response;
        if (this.tabValue == 0) {
          response = await couponApi.getScanList({
            pageNum: page,
            pageSize: size,
            condition: condition
          });
        } else {
          response = await couponApi.getScanGoodsList({
            pageNum: page,
            pageSize: size,
            condition: condition
          });
        }
        let result = response.data;
        // console.log(result)
        if (`${result.code}` === '10000') {
          // 请求成功
          let hasMore = false;
          let resultData = result.data || [];
          hasMore = page < result.pages;
          if (!isPullDown) {
            this.listData.push(...resultData);
          } else {
            this.listData = [...resultData];
          }
          if (!hasMore) {
            this.isLastPage = true;
          }
          await this.handleCallback({
            isError: false,
            hasMore
            // noDataMessage: ' ',
          });
        } else {
          // 请求失败
          await this.handleCallback({
            isError: true,
            errorMessage: result.msg || '获取列表失败!'
          });
        }
      } catch (error) {
        // console.log(error)
        await this.handleCallback({
          isError: true,
          errorMessage: error.message || '获取列表失败!'
        });
      } finally {
        this.isLoading = false;
        if (isShowLoading) await this.$store.dispatch('base/SetLoading', false);
      }
    }
  }
};